<template>  
    <div class="main-menu" role="menu">
        <div class="main-menu-content">
            <div class="menuitem menuitem-logo">  
                <img :src="require('@/assets/img/logo.png')" />
            </div>
            <div class="menuitem menuitem-logout">
                <a href="#" v-if="logged" @click.prevent="doLogout" v-text="username+` (выход)`"></a>
            </div>
        </div>
    </div>
    <router-view />    
</template>


<style lang="scss">


    html,body {
        box-sizing: border-box;
        padding: 0;
        margin: 0;
        font-size: 16px;
        font-family: Tahoma,sans-serif;
        background: #e4fdff;
        height:100%;
        -webkit-tap-highlight-color:rgba(255, 255, 255, 0);

        *{
            -webkit-tap-highlight-color:rgba(255, 255, 255, 0);
        }
    }

    div#app {
        box-sizing: border-box;
        width: 100%;
        position: relative;
        padding-top: 4em;
        height: 100%;
        .app-preloader-construct {
            box-sizing: border-box;
            background: none;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .app-preloader-construct-innert {
                box-sizing: border-box;
                width: 2em;
                height: 2em;
                min-width: 2em;
                max-width: 2em;
                svg {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .main-menu {
        box-sizing: border-box;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 4em;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;
        padding: 0;
        box-shadow: 0 3px 9px #999999;
        background:#e4fdff;
        .main-menu-content {
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            max-width: 1400px;
            margin: 0 auto;
            padding: 0 4em;
        }

    }


    .common-com-view{
        box-sizing: border-box;
        padding: 2em 4em;
        max-width: 1400px;
        width: 100%;
        margin: 0 auto;
    }



    .menuitem.menuitem-logout,.menuitem.menuitem-logout a {
        text-decoration: none;
        color: #3c3b3b;
        cursor: pointer;
    }






    body.mobile-device-mode .main-menu .main-menu-content {
        max-width: 100%;
        width: 100%;
        margin: 0;
        padding: 0 1em;
    }

    body.mobile-device-mode .common-com-view {
        max-width: 100%;
        width: 100%;
        margin: 0;
        padding: 0 1em;
    }


    body.mobile-device-mode .menuitem.menuitem-logo {
        max-width: 45%;
    }
    body.mobile-device-mode .menuitem.menuitem-logo img{
        max-width:100%;
    }

    body.mobile-device-mode  .menuitem.menuitem-logout {
        max-width:50%;
        text-align:right;
    }



</style>
<script>
    import {central_data} from '@/lib/vendor/eve/central-data';
    import U from '@/lib/vendor/eve/lib-utils';
    export default{
        name: 'app-main',
        data: function () {
            return{
                show_menu: false,
                logged: false,
                username: ''
            };
        },
        methods: {
            check_login: function () {
                if (U.IntMoreOr(central_data().jwtMonitor.get_payload('id'), 0, null)) {
                    this.$data.show_menu = true;
                    this.$data.logged = central_data().jwtMonitor.isClient();
                    this.$data.username = this.getUsername();
                } else {
                    this.$data.show_menu = false;
                    this.$data.logged = false;
                    this.$data.username = '';
                }
            },
            getUsername: function () {
                var f = U.NEString(central_data().jwtMonitor.get_payload('family'), '');
                var n = U.NEString(central_data().jwtMonitor.get_payload('name'), '');
                return U.NEString([f, n].join(' '), '');
            },
            doLogout: function () {
                central_data().jwtMonitor.clear_token();
                location.reload(true);
            }
        },
        created: function () {
            central_data().jwtMonitor.on(this, this.check_login);
        },
        mounted: function () {
            this.check_login();
        }

    }
</script>