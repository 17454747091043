/* eslint-disable */
import U from './lib-utils';
import axios from 'axios';
import {callback, callbackManager} from './lib-callback-manager';
import {centralData} from './central-data';

var instance = null;

var counter = 0;

/**
 *  
 * @returns {JWTMonitor}
 */
function JWTMonitor() {
    return JWTMonitor.is(instance) ? instance : ((JWTMonitor.is(this) ? this.init : JWTMonitor.F).apply(this, Array.prototype.slice.call(arguments)));
}

var P = U.fixup_constructor(JWTMonitor).prototype;


P.header = null;
P.token = null;
P.token_data = null;
P.payload = null;
P.callbacks = null;

/**
 * 
 * @param {type} str
 * @returns {unresolved}
 */
function b64DecodeUnicode(str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(atob(str).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}

function decode_payload(x) {
    var xa = U.NEString(x, null);
    if (xa) {
        try {
            var json = b64DecodeUnicode(xa);
            var jso = JSON.parse(json);
            if (U.is_object(jso)) {
                return jso;
            }
        } catch (e) {

        }
    }
    return null;
}

/**
 * 
 * @param {string} nt
 * @returns {undefined}
 */
function set_token(nt) {
    this.token = U.NEString(nt, '--invalid--');
    var ta = this.token.split('.');
    var ntd = null;
    if (ta.length > 2) {
        ntd = ta[1];
    }
    var npl = decode_payload(ntd);
    U.is_object(npl) ? 0 : ntd = null;
    this.payload = U.safe_object(npl);
    var otd = this.token_data;
    this.token_data = ntd;
    localStorage.setItem('lib-jam-token-cli', this.token);
    if (this.token_data !== otd) {
        this.callbacks.run(this);
    }
}

P.clear_token = function () {
    counter++;
    set_token.call(this, null);
};

P.axios_request_interceptor = function (request) {
    request['lib-jam-id'] = counter;
    request.headers[this.header] = this.token;
    return request;
};

P.axios_response_interceptor = function (response) {
    try {
        if (response.config['lib-jam-id'] === counter) {
            var xt = U.NEString(response.headers[this.header], null);
            if (xt) {
                set_token.call(this, xt);
            }
        }
    } catch (ex) {
        console.log(ex);
    }
    return response;
};

P.init = function () {
    instance = this;
    this.header = U.NEString(centralData().frontend_params.filter_value('auth-token-header', 'String:strip=1;trim=1,NEString,DefaultNull'), 'x-auth-token');
    this.callbacks = callbackManager();
    set_token.call(this, U.NEString(localStorage.getItem('lib-jam-token-cli'), '--invalid--'));

    axios.interceptors.request.use(this.axios_request_interceptor.bindTo(this));
    axios.interceptors.response.use(this.axios_response_interceptor.bindTo(this));

    window.Eve = window.Eve || {};
    window.Eve.ADVTable = window.Eve.ADVTable || {};
    window.Eve.ADVTable.Ready = window.Eve.ADVTable.Ready || [];
    window.Eve.ADVTable.Ready.push(this.libTableReady.bindTo(this));

    return this;
};

P.libTableReady = function () {
    window.Eve.ADVTable.DataSource.DSXHR.registerInterceptor(this, function (dsxhr) {
        dsxhr.xhr.setRequestHeader(this.header, this.token);
    });
    window.Eve.ADVTable.DataSource.DSXHR.registerResponseInterceptor(this, function () {
        try {
            var token = U.NEString(arguments[0].xhr.getResponseHeader(this.header), null);
            if (token) {
                set_token.call(this, token);
            }
        } catch (error) {
            //
        }
        return false;
    });
};

P.on = function (cx, ca) {
    this.callbacks.add(cx, ca);
    return this;
};
P.off = function (cx, ca) {
    this.callbacks.remove(cx, ca);
    return this;
};

P.get_payload = function (name, def) {
    if (U.is_object(this.payload) && (name in this.payload)) {
        return this.payload[name];
    }
    return def;
};

P.getPayload = P.get_payload;

P.isAdmin = function () {
    return 'admin' === U.NEString(this.getPayload('role', 'none')).toLowerCase();
};

P.isClient = function () {
    return 'client' === U.NEString(this.getPayload('role', 'none')).toLowerCase();
};
P.isAUserRole = function (anyRoles) {
    if (U.isArray(anyRoles)) {
        for (var i = 0; i < anyRoles.length; i++) {
            if (this.isAUserRole(anyRoles[i])) {
                return true;
            }
        }
    } else if (U.is_string(anyRoles)) {
        return anyRoles.toLowerCase() === U.NEString(this.getPayload('role', 'none')).toLowerCase();
    }
    return false;
};

export {JWTMonitor};