import U from './lib-utils';

var locale_decimal_separator = null;

function get_locale_separator() {
    if (!locale_decimal_separator) {
        var n = 1.1;
        locale_decimal_separator = n.toLocaleString().substring(1, 2);
    }
    return locale_decimal_separator;
}
function clean_phone(x) {
    if (U.is_string(x)) {
        return x.replace(/\D/g, '');
    }
    return null;
}

function split_phone(x) {
    var cf = clean_phone(x);
    if (cf) {
        var m = /^(\d{1,})(\d{3})(\d{3})(\d{2})(\d{2})$/.exec(cf);
        if (m) {
            return {
                co: m[1],
                lo: m[2],
                o1: m[3],
                o2: m[4],
                o3: m[5]
            };
        }
    }
    return null;
}
function format_phone(phone, format, def) {
    format = U.NEString(format, '+%co% (%lo%) %o1% %o2% %o3%');
    var spl = split_phone(phone);
    if (spl) {
        var df = format;
        for (var k in spl) {
            if (Object.prototype.hasOwnProperty.call(spl, k)) {
                df = df.replace(new RegExp(['%', k, '%'].join(''), 'ig'), spl[k]);
            }
        }
        return df;
    }
    if (void(0) === def) {
        return phone;
    }
    return def;
}



function format_float(v, decimals, split_decimals, with_locale) {
    var fv = U.floatOr(v, null);
    if (null !== fv) {
        var dec = U.intMoreOr(decimals, 0, 2);
        if (U.any_bool(split_decimals, false)) {
            var nloc = fv.toFixed(dec).toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
            if (U.any_bool(with_locale, false)) {
                nloc = nloc.replace(/\./g, get_locale_separator());
            }
            return nloc;
        }
        var e = fv.toFixed(dec).toString();
        var ae = e.split('.');
        var r = [ae[0].replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')];
        var nl = [r, ae[1] ? ae[1] : U.pad_left('', dec, '0')].join('.');
        if (U.any_bool(with_locale, false)) {
            // eslint-disable-next-line
            nl = nl.replace(/\./g, get_locale_separator());
        }
        return nl;
    }
    return null;
}

//<editor-fold defaultstate="collapsed" desc="date formatter">        
function dow_short(d) {
    try {
        d.toLocaleString('i');
        return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'][d.getDay()];
    } catch (e) {
        return d.toLocaleString(navigator.language, {'weekday': 'short'});
    }
}
function dow_full(d) {
    try {
        d.toLocaleString('i');
        return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'][d.getDay()];
    } catch (e) {
        return d.toLocaleString(navigator.language, {'weekday': 'long'});
    }
}
function mon_full(d) {
    try {
        d.toLocaleString('i');
        return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][d.getMonth()];
    } catch (e) {
        return d.toLocaleString(navigator.language, {'month': 'long'});
    }
}
function mon_short(d) {
    try {
        d.toLocaleString('i');
        return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][d.getMonth()];
    } catch (e) {
        return d.toLocaleString(navigator.language, {'month': 'short'});
    }
}
function format_date_time(d, f) {
    if (U.is_object(d) && (d instanceof Date)) {
        var res = U.NEString(f, 'd.m.Y');
        var ddf = {
            'd': U.pad_left(d.getDate(), 2, '0'),
            'D': dow_short(d),
            'j': [d.getDate()].join(''),
            'l': dow_full(d),
            'N': d.getDay() + 1,
            'w': d.getDay(),
            'F': mon_full(d),
            'm': U.pad_left(d.getMonth() + 1, 2, '0'),
            'M': mon_short(d),
            'n': d.getMonth() + 1,
            'Y': d.getFullYear(),
            'y': U.pad_left(U.intMoreOr([d.getFullYear()].join('').substr(2), 0, 0), 2, '0'),
            //time
            'H': U.pad_left(d.getHours(), 2, '0'),
            'G': [d.getHours()].join(''),
            'i': U.pad_left(d.getMinutes(), 2, '0'),
            's': U.pad_left(d.getSeconds(), 2, '0'),
            'U': (d.getTime() / 1000).toFixed(0),
            'a': d.getHours() >= 12 ? 'pm' : 'am',
            'A': d.getHours() >= 12 ? 'PM' : 'AM',
            'g': [(d.getHours() % 12) ? (d.getHours() % 12) : 12].join(''),
            'h': U.pad_left([(d.getHours() % 12) ? (d.getHours() % 12) : 12].join(''), 2, '0')
        };
        for (var k in ddf) {
            if (Object.prototype.hasOwnProperty.call(ddf, k)) {
                if (!U.is_callable(ddf[k]) && !U.is_object(ddf[k]) && k.length === 1) {
                    var rx = new RegExp(k, 'g');
                    res = res.replace(rx, ddf[k]);
                }
            }
        }
        return res;
    }
    return null;
}
format_date_time.mon_short = mon_short;
format_date_time.mon_full = mon_full;
format_date_time.dow_full = dow_full;
format_date_time.dow_short = dow_short;




//</editor-fold>     

export default{
    format_float: format_float,
    format_phone: format_phone,
    split_phone: split_phone,
    format_date_time: format_date_time,
    clean_phone:clean_phone
};